<template>
    <div class="account-content">
        <div class="create-btn">
            <div class="create-title">剪辑库</div>
        </div>
        <div class="sort-list">
            <div class="left-sort">
                <div class="left1" :class="{active:isCreateTime==true}" @click="selectSort()">
                    创建时间
                    <span>
                        <i class="iconfont">&#xe65f;</i>
                    </span>
                </div>
            </div>
            <div class="right-sort">
                <el-input v-model="captionSearch" size="small" placeholder="输入关键词">
                    <i class="el-icon-search el-input__icon search-btn" slot="suffix" @click="searchHotCaption"></i>
                </el-input>
            </div>
        </div>
        <div class="sort-list">
            <div class="left-sort">
                <div class="left1">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                        全选(已选择{{selectedNum}}个)
                    </el-checkbox>
                </div>
                <div class="left2" @click="deletePro">
                    <i class="iconfont">&#xe638;</i>
                    <span style="padding-left: 2px;">删除</span>
                </div>
            </div>
        </div>
        <div class="res-list">
            <div class="tab-content">
                <div class="card" v-for="item in videoList" :key="item.id">
                    <div class="newContain" @click="showDetail(item)">
                        <div class="checkbox" style="float: right;">
                            <el-checkbox-group v-model="checkedLists" @change="selectPro">
                                <el-checkbox :label="item.id"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <img class="pic-box" :src="item.video_cover.url" alt="">
                    </div>
                    <div class="container">
                        <div class="title-box">
                            <div>{{item.title}}</div>
                            <div>最后一次更新:{{item.update_time}}</div>
                        </div>
                        <div class="left3">
                            <el-button type="primary" style="opacity: .7;" disabled @click="publishVideo(item)">发布</el-button>
                            <el-button type="primary" @click="downloadVideo(item)">下载</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
                class="pages-center" background style="padding-bottom: 10px;"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
        <el-dialog title="视频详情" :visible.sync="dialogVideoDetail" width="900px" class="VideoDetail-dialog"
                   :before-close="handleClose">
            <video width="100%" height="500px" :src="videoDetailData.videoUrl" controls="true"
                   controlslist="nodownload"></video>
            <div class="VideoDetail-btn">
                <div class="operating-video">
                    <span style="margin-right: 40px;">发布时间：{{videoDetailData.create_time}}</span>
                </div>
                <div class="btn-del-video" @click="deleteSingle(videoDetailData.videoId)">
                    <i class="iconfont">&#xe74b;</i>删除视频
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions,mapMutations} from 'vuex'
    export default {
        name: "ShortVideoModule",
        data() {
            return {
                dialogVideoDetail: false,
                videoList: [],
                videoIdList: [],
                checkedLists: [],
                selectedNum: 0,
                checkAll: false,
                isIndeterminate: true,
                shortTable: [],
                currentSort: 0,
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                captionSearch: "",
                count: 0,
                isCreateTime: false,
                //视频详情
                videoDetailData: {
                    videoUrl: '',
                    create_time: '',
                    videoId: '',
                },
            }
        },
        mounted() {
            this.getVideoList();
        },
        methods: {
            ...mapMutations(['setFilePath', 'setFilePathUrl']),
            getVideoList(val) {
                let param = {
                    status: 1,
                    title: this.captionSearch,
                    page: this.studentPages.currentPageNum,
                    limit: this.studentPages.eachPageNum,
                }
                if (val) {
                    param.sort = val;
                }
                this.$httpStudent.axiosGetBy(this.$api.videoList, param, res => {
                    if (res.code == 200) {
                        this.videoList = res.data.data;
                        this.videoList.forEach(item => {
                            this.videoIdList.push(item.id)
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            searchHotCaption() {
                this.getVideoList();
            },
            //全选
            handleCheckAllChange(val) {
                this.checkedLists = val ? this.videoIdList : [];
                this.isIndeterminate = false;
                this.selectedNum = this.checkedLists.length;
            },
            studentCurrentChange(val) {
                this.studentPages.currentPageNum = val;
                this.getAccountList()
            },
            //单选
            selectPro(val) {
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.videoList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.videoList.length;
                this.selectedNum = val.length;
            },
            deletePro() {
                if (this.checkedLists.length == 0) {
                    this.$message.warning("请选择至少一个视频")
                    return
                }
                this.$confirm('删除后不能恢复，确定是否删除？', '删除项目', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = this.checkedLists.join(',');
                    this.$httpStudent.axiosGetBy(this.$api.deleteVideo, {id: ids}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.checkedLists = [];
                            this.selectedNum = 0;
                            this.getVideoList();
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            deleteSingle(id) {
                this.$confirm('删除后不能恢复，确定是否删除？', '删除项目', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$httpStudent.axiosGetBy(this.$api.deleteVideo, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.checkedLists = [];
                            this.selectedNum = 0;
                            this.getVideoList();
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            selectSort(val) {
                if (this.count > 9) {
                    this.count = 0;
                }
                this.count++;
                this.isCreateTime = true;
                let order = this.count % 2 == 0 ? 2 : 1;
                this.getVideoList(order)
            },
            showDetail(item) {
                this.dialogVideoDetail = true;
                this.videoDetailData.videoUrl = item.video_file.url;
                this.videoDetailData.create_time = item.create_time;
                this.videoDetailData.videoId = item.id;
            },
            handleClose() {
                this.dialogVideoDetail = false;
                this.videoDetailData.videoUrl = '';
                this.videoDetailData.create_time = '';
                this.videoDetailData.videoId = '';
            },
            publishVideo(item) {
                this.setFilePath(item.video_file.src);
                this.setFilePathUrl(item.video_file.url);
                this.$router.push({
                    path: '/student/video/publish',
                    query: {
                        ShortTab : 2,//剪辑库上传
                        vId: item.id
                    }
                });
            },
            downloadVideo(item){
                var xhr = new XMLHttpRequest();
                xhr.open('GET', item.video_file.url, true);
                xhr.responseType = 'blob';    // 返回类型blob
                xhr.onload = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let blob = this.response;
                        // 转换一个blob链接
                        let u = window.URL.createObjectURL(new Blob([blob]))
                        let a = document.createElement('a');
                        a.download = item.title+".mp4";
                        a.href = u;
                        a.style.display = 'none'
                        document.body.appendChild(a)
                        a.click();
                        a.remove();
                    }
                };
                xhr.send()

            },
            //防抖
            sendAjax: _.debounce(function () {
                this.getVideoList()
            }, 1000),
        },
        watch: {
            captionSearch(d) {
                this.sendAjax();
            }
        },
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-input__inner {
        border-radius: 15px;
    }

    ::v-deep .el-dialog__header {
        background: #F1F0FE;
        border-radius: 10px 10px 0px 0px;
    }

    .account-content {
        height: 100%;

        .create-btn {
            padding-bottom: 30px;
        }

        .create-title {
            width: 100px;
            height: 19px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3A3567;
        }

        .res-list {
            min-height: calc(100vh - 380px);
        }

        .tab-content {
            display: flex;
            flex-wrap: wrap;

            .card {
                box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.2);
                width: calc(20% - 25px);
                min-width: 250px;
                height: 260px;
                cursor: pointer;
                margin: 0 20px 20px 0;
                background: #EBEBF9;

                .newContain {
                    width: 100%;
                    height: 160px;
                    border-radius: 4px;
                    cursor: pointer;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .checkbox {
                        position: absolute;
                        right: 0;
                        padding: 10px 10px 0 0;

                        ::v-deep .el-checkbox__label {
                            display: none;
                        }
                    }
                }

                .mainContain {
                    width: 100%;
                    height: 160px;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    background: #16113F;
                    justify-content: center;

                    .pic_box {
                        display: flex;
                        align-items: center;
                    }
                }

                .container {

                    .title-box {
                        padding: 6px 0 0 14px;
                    }

                    .left3 {
                        display: flex;
                        justify-content: space-between;
                        padding: 18px 10px 0 10px;

                        ::v-deep .el-button {
                            width: 90px;
                            height: 20px;
                            line-height: 0;
                            background: #594FEE;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }

        .sort-list {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;

            .left-sort {
                display: flex;

                .active {
                    cursor: pointer;
                    color: #574DED;
                }

                .left1 {
                    cursor: pointer;
                }

                .left2 {
                    padding-left: 30px;
                    cursor: pointer;
                }
            }
        }

        .VideoDetail-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            .btn-del-video {
                color: #FB0360;
                cursor: pointer;

                i {
                    margin-right: 10px;
                    font-size: 14px;
                }
            }
        }
    }

</style>